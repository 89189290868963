// source: core/contract/balance_contract.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    function () {
        return this;
    }.call(null) ||
    Function('return this')();

var core_contract_common_pb = require('../../core/contract/common_pb.cjs');
goog.object.extend(proto, core_contract_common_pb);
goog.exportSymbol('TronWebProto.AccountBalanceRequest', null, global);
goog.exportSymbol('TronWebProto.AccountBalanceResponse', null, global);
goog.exportSymbol('TronWebProto.AccountIdentifier', null, global);
goog.exportSymbol('TronWebProto.AccountTrace', null, global);
goog.exportSymbol('TronWebProto.BlockBalanceTrace', null, global);
goog.exportSymbol('TronWebProto.BlockBalanceTrace.BlockIdentifier', null, global);
goog.exportSymbol('TronWebProto.CancelAllUnfreezeV2Contract', null, global);
goog.exportSymbol('TronWebProto.DelegateResourceContract', null, global);
goog.exportSymbol('TronWebProto.FreezeBalanceContract', null, global);
goog.exportSymbol('TronWebProto.FreezeBalanceV2Contract', null, global);
goog.exportSymbol('TronWebProto.TransactionBalanceTrace', null, global);
goog.exportSymbol('TronWebProto.TransactionBalanceTrace.Operation', null, global);
goog.exportSymbol('TronWebProto.TransferContract', null, global);
goog.exportSymbol('TronWebProto.UnDelegateResourceContract', null, global);
goog.exportSymbol('TronWebProto.UnfreezeBalanceContract', null, global);
goog.exportSymbol('TronWebProto.UnfreezeBalanceV2Contract', null, global);
goog.exportSymbol('TronWebProto.WithdrawBalanceContract', null, global);
goog.exportSymbol('TronWebProto.WithdrawExpireUnfreezeContract', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.FreezeBalanceContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.FreezeBalanceContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.FreezeBalanceContract.displayName = 'TronWebProto.FreezeBalanceContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.UnfreezeBalanceContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.UnfreezeBalanceContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.UnfreezeBalanceContract.displayName = 'TronWebProto.UnfreezeBalanceContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.WithdrawBalanceContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.WithdrawBalanceContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.WithdrawBalanceContract.displayName = 'TronWebProto.WithdrawBalanceContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.TransferContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.TransferContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.TransferContract.displayName = 'TronWebProto.TransferContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.TransactionBalanceTrace = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.TransactionBalanceTrace.repeatedFields_, null);
};
goog.inherits(TronWebProto.TransactionBalanceTrace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.TransactionBalanceTrace.displayName = 'TronWebProto.TransactionBalanceTrace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.TransactionBalanceTrace.Operation = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.TransactionBalanceTrace.Operation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.TransactionBalanceTrace.Operation.displayName = 'TronWebProto.TransactionBalanceTrace.Operation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.BlockBalanceTrace = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, TronWebProto.BlockBalanceTrace.repeatedFields_, null);
};
goog.inherits(TronWebProto.BlockBalanceTrace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.BlockBalanceTrace.displayName = 'TronWebProto.BlockBalanceTrace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.BlockBalanceTrace.BlockIdentifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.BlockBalanceTrace.BlockIdentifier.displayName = 'TronWebProto.BlockBalanceTrace.BlockIdentifier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.AccountTrace = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.AccountTrace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.AccountTrace.displayName = 'TronWebProto.AccountTrace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.AccountIdentifier = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.AccountIdentifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.AccountIdentifier.displayName = 'TronWebProto.AccountIdentifier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.AccountBalanceRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.AccountBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.AccountBalanceRequest.displayName = 'TronWebProto.AccountBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.AccountBalanceResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.AccountBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.AccountBalanceResponse.displayName = 'TronWebProto.AccountBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.FreezeBalanceV2Contract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.FreezeBalanceV2Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.FreezeBalanceV2Contract.displayName = 'TronWebProto.FreezeBalanceV2Contract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.UnfreezeBalanceV2Contract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.UnfreezeBalanceV2Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.UnfreezeBalanceV2Contract.displayName = 'TronWebProto.UnfreezeBalanceV2Contract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.WithdrawExpireUnfreezeContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.WithdrawExpireUnfreezeContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.WithdrawExpireUnfreezeContract.displayName = 'TronWebProto.WithdrawExpireUnfreezeContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.DelegateResourceContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.DelegateResourceContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.DelegateResourceContract.displayName = 'TronWebProto.DelegateResourceContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.UnDelegateResourceContract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.UnDelegateResourceContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.UnDelegateResourceContract.displayName = 'TronWebProto.UnDelegateResourceContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
TronWebProto.CancelAllUnfreezeV2Contract = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(TronWebProto.CancelAllUnfreezeV2Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    TronWebProto.CancelAllUnfreezeV2Contract.displayName = 'TronWebProto.CancelAllUnfreezeV2Contract';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.FreezeBalanceContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.FreezeBalanceContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.FreezeBalanceContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.FreezeBalanceContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                frozenBalance: jspb.Message.getFieldWithDefault(msg, 2, 0),
                frozenDuration: jspb.Message.getFieldWithDefault(msg, 3, 0),
                resource: jspb.Message.getFieldWithDefault(msg, 10, 0),
                receiverAddress: msg.getReceiverAddress_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.FreezeBalanceContract}
 */
TronWebProto.FreezeBalanceContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.FreezeBalanceContract();
    return TronWebProto.FreezeBalanceContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.FreezeBalanceContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.FreezeBalanceContract}
 */
TronWebProto.FreezeBalanceContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFrozenBalance(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFrozenDuration(value);
                break;
            case 10:
                var value = /** @type {!TronWebProto.ResourceCode} */ (reader.readEnum());
                msg.setResource(value);
                break;
            case 15:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setReceiverAddress(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.FreezeBalanceContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.FreezeBalanceContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.FreezeBalanceContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.FreezeBalanceContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getFrozenBalance();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getFrozenDuration();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getResource();
    if (f !== 0.0) {
        writer.writeEnum(10, f);
    }
    f = message.getReceiverAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(15, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.FreezeBalanceContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.FreezeBalanceContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.FreezeBalanceContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.FreezeBalanceContract} returns this
 */
TronWebProto.FreezeBalanceContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional int64 frozen_balance = 2;
 * @return {number}
 */
TronWebProto.FreezeBalanceContract.prototype.getFrozenBalance = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.FreezeBalanceContract} returns this
 */
TronWebProto.FreezeBalanceContract.prototype.setFrozenBalance = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int64 frozen_duration = 3;
 * @return {number}
 */
TronWebProto.FreezeBalanceContract.prototype.getFrozenDuration = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.FreezeBalanceContract} returns this
 */
TronWebProto.FreezeBalanceContract.prototype.setFrozenDuration = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional ResourceCode resource = 10;
 * @return {!TronWebProto.ResourceCode}
 */
TronWebProto.FreezeBalanceContract.prototype.getResource = function () {
    return /** @type {!TronWebProto.ResourceCode} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};

/**
 * @param {!TronWebProto.ResourceCode} value
 * @return {!TronWebProto.FreezeBalanceContract} returns this
 */
TronWebProto.FreezeBalanceContract.prototype.setResource = function (value) {
    return jspb.Message.setProto3EnumField(this, 10, value);
};

/**
 * optional bytes receiver_address = 15;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.FreezeBalanceContract.prototype.getReceiverAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ''));
};

/**
 * optional bytes receiver_address = 15;
 * This is a type-conversion wrapper around `getReceiverAddress()`
 * @return {string}
 */
TronWebProto.FreezeBalanceContract.prototype.getReceiverAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getReceiverAddress()));
};

/**
 * optional bytes receiver_address = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReceiverAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.FreezeBalanceContract.prototype.getReceiverAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getReceiverAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.FreezeBalanceContract} returns this
 */
TronWebProto.FreezeBalanceContract.prototype.setReceiverAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 15, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.UnfreezeBalanceContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.UnfreezeBalanceContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.UnfreezeBalanceContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.UnfreezeBalanceContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                resource: jspb.Message.getFieldWithDefault(msg, 10, 0),
                receiverAddress: msg.getReceiverAddress_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.UnfreezeBalanceContract}
 */
TronWebProto.UnfreezeBalanceContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.UnfreezeBalanceContract();
    return TronWebProto.UnfreezeBalanceContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.UnfreezeBalanceContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.UnfreezeBalanceContract}
 */
TronWebProto.UnfreezeBalanceContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 10:
                var value = /** @type {!TronWebProto.ResourceCode} */ (reader.readEnum());
                msg.setResource(value);
                break;
            case 15:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setReceiverAddress(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.UnfreezeBalanceContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.UnfreezeBalanceContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.UnfreezeBalanceContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.UnfreezeBalanceContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getResource();
    if (f !== 0.0) {
        writer.writeEnum(10, f);
    }
    f = message.getReceiverAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(15, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UnfreezeBalanceContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.UnfreezeBalanceContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UnfreezeBalanceContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UnfreezeBalanceContract} returns this
 */
TronWebProto.UnfreezeBalanceContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional ResourceCode resource = 10;
 * @return {!TronWebProto.ResourceCode}
 */
TronWebProto.UnfreezeBalanceContract.prototype.getResource = function () {
    return /** @type {!TronWebProto.ResourceCode} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};

/**
 * @param {!TronWebProto.ResourceCode} value
 * @return {!TronWebProto.UnfreezeBalanceContract} returns this
 */
TronWebProto.UnfreezeBalanceContract.prototype.setResource = function (value) {
    return jspb.Message.setProto3EnumField(this, 10, value);
};

/**
 * optional bytes receiver_address = 15;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UnfreezeBalanceContract.prototype.getReceiverAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ''));
};

/**
 * optional bytes receiver_address = 15;
 * This is a type-conversion wrapper around `getReceiverAddress()`
 * @return {string}
 */
TronWebProto.UnfreezeBalanceContract.prototype.getReceiverAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getReceiverAddress()));
};

/**
 * optional bytes receiver_address = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReceiverAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UnfreezeBalanceContract.prototype.getReceiverAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getReceiverAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UnfreezeBalanceContract} returns this
 */
TronWebProto.UnfreezeBalanceContract.prototype.setReceiverAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 15, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.WithdrawBalanceContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.WithdrawBalanceContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.WithdrawBalanceContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.WithdrawBalanceContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.WithdrawBalanceContract}
 */
TronWebProto.WithdrawBalanceContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.WithdrawBalanceContract();
    return TronWebProto.WithdrawBalanceContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.WithdrawBalanceContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.WithdrawBalanceContract}
 */
TronWebProto.WithdrawBalanceContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.WithdrawBalanceContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.WithdrawBalanceContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.WithdrawBalanceContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.WithdrawBalanceContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.WithdrawBalanceContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.WithdrawBalanceContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.WithdrawBalanceContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.WithdrawBalanceContract} returns this
 */
TronWebProto.WithdrawBalanceContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.TransferContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.TransferContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.TransferContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.TransferContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                toAddress: msg.getToAddress_asB64(),
                amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.TransferContract}
 */
TronWebProto.TransferContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.TransferContract();
    return TronWebProto.TransferContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.TransferContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.TransferContract}
 */
TronWebProto.TransferContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setToAddress(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.TransferContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.TransferContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.TransferContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.TransferContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getToAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
    f = message.getAmount();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.TransferContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.TransferContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.TransferContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.TransferContract} returns this
 */
TronWebProto.TransferContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes to_address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.TransferContract.prototype.getToAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * optional bytes to_address = 2;
 * This is a type-conversion wrapper around `getToAddress()`
 * @return {string}
 */
TronWebProto.TransferContract.prototype.getToAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getToAddress()));
};

/**
 * optional bytes to_address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getToAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.TransferContract.prototype.getToAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getToAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.TransferContract} returns this
 */
TronWebProto.TransferContract.prototype.setToAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * optional int64 amount = 3;
 * @return {number}
 */
TronWebProto.TransferContract.prototype.getAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.TransferContract} returns this
 */
TronWebProto.TransferContract.prototype.setAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.TransactionBalanceTrace.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.TransactionBalanceTrace.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.TransactionBalanceTrace.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.TransactionBalanceTrace} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.TransactionBalanceTrace.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                transactionIdentifier: msg.getTransactionIdentifier_asB64(),
                operationList: jspb.Message.toObjectList(
                    msg.getOperationList(),
                    TronWebProto.TransactionBalanceTrace.Operation.toObject,
                    includeInstance
                ),
                type: jspb.Message.getFieldWithDefault(msg, 3, ''),
                status: jspb.Message.getFieldWithDefault(msg, 4, ''),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.TransactionBalanceTrace}
 */
TronWebProto.TransactionBalanceTrace.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.TransactionBalanceTrace();
    return TronWebProto.TransactionBalanceTrace.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.TransactionBalanceTrace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.TransactionBalanceTrace}
 */
TronWebProto.TransactionBalanceTrace.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setTransactionIdentifier(value);
                break;
            case 2:
                var value = new TronWebProto.TransactionBalanceTrace.Operation();
                reader.readMessage(value, TronWebProto.TransactionBalanceTrace.Operation.deserializeBinaryFromReader);
                msg.addOperation(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setType(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.TransactionBalanceTrace.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.TransactionBalanceTrace.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.TransactionBalanceTrace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.TransactionBalanceTrace.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTransactionIdentifier_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getOperationList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, TronWebProto.TransactionBalanceTrace.Operation.serializeBinaryToWriter);
    }
    f = message.getType();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.TransactionBalanceTrace.Operation.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.TransactionBalanceTrace.Operation.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.TransactionBalanceTrace.Operation} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.TransactionBalanceTrace.Operation.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                operationIdentifier: jspb.Message.getFieldWithDefault(msg, 1, 0),
                address: msg.getAddress_asB64(),
                amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.TransactionBalanceTrace.Operation}
 */
TronWebProto.TransactionBalanceTrace.Operation.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.TransactionBalanceTrace.Operation();
    return TronWebProto.TransactionBalanceTrace.Operation.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.TransactionBalanceTrace.Operation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.TransactionBalanceTrace.Operation}
 */
TronWebProto.TransactionBalanceTrace.Operation.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setOperationIdentifier(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setAddress(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.TransactionBalanceTrace.Operation.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.TransactionBalanceTrace.Operation.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.TransactionBalanceTrace.Operation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.TransactionBalanceTrace.Operation.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOperationIdentifier();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
    f = message.getAmount();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};

/**
 * optional int64 operation_identifier = 1;
 * @return {number}
 */
TronWebProto.TransactionBalanceTrace.Operation.prototype.getOperationIdentifier = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.TransactionBalanceTrace.Operation} returns this
 */
TronWebProto.TransactionBalanceTrace.Operation.prototype.setOperationIdentifier = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional bytes address = 2;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.TransactionBalanceTrace.Operation.prototype.getAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * optional bytes address = 2;
 * This is a type-conversion wrapper around `getAddress()`
 * @return {string}
 */
TronWebProto.TransactionBalanceTrace.Operation.prototype.getAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getAddress()));
};

/**
 * optional bytes address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.TransactionBalanceTrace.Operation.prototype.getAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.TransactionBalanceTrace.Operation} returns this
 */
TronWebProto.TransactionBalanceTrace.Operation.prototype.setAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * optional int64 amount = 3;
 * @return {number}
 */
TronWebProto.TransactionBalanceTrace.Operation.prototype.getAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.TransactionBalanceTrace.Operation} returns this
 */
TronWebProto.TransactionBalanceTrace.Operation.prototype.setAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional bytes transaction_identifier = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.TransactionBalanceTrace.prototype.getTransactionIdentifier = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes transaction_identifier = 1;
 * This is a type-conversion wrapper around `getTransactionIdentifier()`
 * @return {string}
 */
TronWebProto.TransactionBalanceTrace.prototype.getTransactionIdentifier_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getTransactionIdentifier()));
};

/**
 * optional bytes transaction_identifier = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTransactionIdentifier()`
 * @return {!Uint8Array}
 */
TronWebProto.TransactionBalanceTrace.prototype.getTransactionIdentifier_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getTransactionIdentifier()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.TransactionBalanceTrace} returns this
 */
TronWebProto.TransactionBalanceTrace.prototype.setTransactionIdentifier = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * repeated Operation operation = 2;
 * @return {!Array<!TronWebProto.TransactionBalanceTrace.Operation>}
 */
TronWebProto.TransactionBalanceTrace.prototype.getOperationList = function () {
    return /** @type{!Array<!TronWebProto.TransactionBalanceTrace.Operation>} */ (
        jspb.Message.getRepeatedWrapperField(this, TronWebProto.TransactionBalanceTrace.Operation, 2)
    );
};

/**
 * @param {!Array<!TronWebProto.TransactionBalanceTrace.Operation>} value
 * @return {!TronWebProto.TransactionBalanceTrace} returns this
 */
TronWebProto.TransactionBalanceTrace.prototype.setOperationList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!TronWebProto.TransactionBalanceTrace.Operation=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.TransactionBalanceTrace.Operation}
 */
TronWebProto.TransactionBalanceTrace.prototype.addOperation = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, TronWebProto.TransactionBalanceTrace.Operation, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.TransactionBalanceTrace} returns this
 */
TronWebProto.TransactionBalanceTrace.prototype.clearOperationList = function () {
    return this.setOperationList([]);
};

/**
 * optional string type = 3;
 * @return {string}
 */
TronWebProto.TransactionBalanceTrace.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!TronWebProto.TransactionBalanceTrace} returns this
 */
TronWebProto.TransactionBalanceTrace.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string status = 4;
 * @return {string}
 */
TronWebProto.TransactionBalanceTrace.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!TronWebProto.TransactionBalanceTrace} returns this
 */
TronWebProto.TransactionBalanceTrace.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
TronWebProto.BlockBalanceTrace.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.BlockBalanceTrace.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.BlockBalanceTrace.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.BlockBalanceTrace} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.BlockBalanceTrace.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                blockIdentifier:
                    (f = msg.getBlockIdentifier()) && TronWebProto.BlockBalanceTrace.BlockIdentifier.toObject(includeInstance, f),
                timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
                transactionBalanceTraceList: jspb.Message.toObjectList(
                    msg.getTransactionBalanceTraceList(),
                    TronWebProto.TransactionBalanceTrace.toObject,
                    includeInstance
                ),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.BlockBalanceTrace}
 */
TronWebProto.BlockBalanceTrace.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.BlockBalanceTrace();
    return TronWebProto.BlockBalanceTrace.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.BlockBalanceTrace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.BlockBalanceTrace}
 */
TronWebProto.BlockBalanceTrace.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new TronWebProto.BlockBalanceTrace.BlockIdentifier();
                reader.readMessage(value, TronWebProto.BlockBalanceTrace.BlockIdentifier.deserializeBinaryFromReader);
                msg.setBlockIdentifier(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTimestamp(value);
                break;
            case 3:
                var value = new TronWebProto.TransactionBalanceTrace();
                reader.readMessage(value, TronWebProto.TransactionBalanceTrace.deserializeBinaryFromReader);
                msg.addTransactionBalanceTrace(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.BlockBalanceTrace.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.BlockBalanceTrace.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.BlockBalanceTrace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.BlockBalanceTrace.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBlockIdentifier();
    if (f != null) {
        writer.writeMessage(1, f, TronWebProto.BlockBalanceTrace.BlockIdentifier.serializeBinaryToWriter);
    }
    f = message.getTimestamp();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getTransactionBalanceTraceList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, TronWebProto.TransactionBalanceTrace.serializeBinaryToWriter);
    }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.BlockBalanceTrace.BlockIdentifier.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.BlockBalanceTrace.BlockIdentifier.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.BlockBalanceTrace.BlockIdentifier} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.BlockBalanceTrace.BlockIdentifier.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                hash: msg.getHash_asB64(),
                number: jspb.Message.getFieldWithDefault(msg, 2, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.BlockBalanceTrace.BlockIdentifier}
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.BlockBalanceTrace.BlockIdentifier();
    return TronWebProto.BlockBalanceTrace.BlockIdentifier.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.BlockBalanceTrace.BlockIdentifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.BlockBalanceTrace.BlockIdentifier}
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setHash(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumber(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.BlockBalanceTrace.BlockIdentifier.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.BlockBalanceTrace.BlockIdentifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getHash_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getNumber();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};

/**
 * optional bytes hash = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier.prototype.getHash = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes hash = 1;
 * This is a type-conversion wrapper around `getHash()`
 * @return {string}
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier.prototype.getHash_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getHash()));
};

/**
 * optional bytes hash = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHash()`
 * @return {!Uint8Array}
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier.prototype.getHash_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getHash()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.BlockBalanceTrace.BlockIdentifier} returns this
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier.prototype.setHash = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional int64 number = 2;
 * @return {number}
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier.prototype.getNumber = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.BlockBalanceTrace.BlockIdentifier} returns this
 */
TronWebProto.BlockBalanceTrace.BlockIdentifier.prototype.setNumber = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional BlockIdentifier block_identifier = 1;
 * @return {?TronWebProto.BlockBalanceTrace.BlockIdentifier}
 */
TronWebProto.BlockBalanceTrace.prototype.getBlockIdentifier = function () {
    return /** @type{?TronWebProto.BlockBalanceTrace.BlockIdentifier} */ (
        jspb.Message.getWrapperField(this, TronWebProto.BlockBalanceTrace.BlockIdentifier, 1)
    );
};

/**
 * @param {?TronWebProto.BlockBalanceTrace.BlockIdentifier|undefined} value
 * @return {!TronWebProto.BlockBalanceTrace} returns this
 */
TronWebProto.BlockBalanceTrace.prototype.setBlockIdentifier = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.BlockBalanceTrace} returns this
 */
TronWebProto.BlockBalanceTrace.prototype.clearBlockIdentifier = function () {
    return this.setBlockIdentifier(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.BlockBalanceTrace.prototype.hasBlockIdentifier = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
TronWebProto.BlockBalanceTrace.prototype.getTimestamp = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.BlockBalanceTrace} returns this
 */
TronWebProto.BlockBalanceTrace.prototype.setTimestamp = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * repeated TransactionBalanceTrace transaction_balance_trace = 3;
 * @return {!Array<!TronWebProto.TransactionBalanceTrace>}
 */
TronWebProto.BlockBalanceTrace.prototype.getTransactionBalanceTraceList = function () {
    return /** @type{!Array<!TronWebProto.TransactionBalanceTrace>} */ (
        jspb.Message.getRepeatedWrapperField(this, TronWebProto.TransactionBalanceTrace, 3)
    );
};

/**
 * @param {!Array<!TronWebProto.TransactionBalanceTrace>} value
 * @return {!TronWebProto.BlockBalanceTrace} returns this
 */
TronWebProto.BlockBalanceTrace.prototype.setTransactionBalanceTraceList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!TronWebProto.TransactionBalanceTrace=} opt_value
 * @param {number=} opt_index
 * @return {!TronWebProto.TransactionBalanceTrace}
 */
TronWebProto.BlockBalanceTrace.prototype.addTransactionBalanceTrace = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, TronWebProto.TransactionBalanceTrace, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!TronWebProto.BlockBalanceTrace} returns this
 */
TronWebProto.BlockBalanceTrace.prototype.clearTransactionBalanceTraceList = function () {
    return this.setTransactionBalanceTraceList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.AccountTrace.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.AccountTrace.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.AccountTrace} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.AccountTrace.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                balance: jspb.Message.getFieldWithDefault(msg, 1, 0),
                placeholder: jspb.Message.getFieldWithDefault(msg, 99, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.AccountTrace}
 */
TronWebProto.AccountTrace.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.AccountTrace();
    return TronWebProto.AccountTrace.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.AccountTrace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.AccountTrace}
 */
TronWebProto.AccountTrace.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBalance(value);
                break;
            case 99:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPlaceholder(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.AccountTrace.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.AccountTrace.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.AccountTrace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.AccountTrace.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBalance();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getPlaceholder();
    if (f !== 0) {
        writer.writeInt64(99, f);
    }
};

/**
 * optional int64 balance = 1;
 * @return {number}
 */
TronWebProto.AccountTrace.prototype.getBalance = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.AccountTrace} returns this
 */
TronWebProto.AccountTrace.prototype.setBalance = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int64 placeholder = 99;
 * @return {number}
 */
TronWebProto.AccountTrace.prototype.getPlaceholder = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.AccountTrace} returns this
 */
TronWebProto.AccountTrace.prototype.setPlaceholder = function (value) {
    return jspb.Message.setProto3IntField(this, 99, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.AccountIdentifier.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.AccountIdentifier.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.AccountIdentifier} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.AccountIdentifier.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                address: msg.getAddress_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.AccountIdentifier}
 */
TronWebProto.AccountIdentifier.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.AccountIdentifier();
    return TronWebProto.AccountIdentifier.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.AccountIdentifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.AccountIdentifier}
 */
TronWebProto.AccountIdentifier.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setAddress(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.AccountIdentifier.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.AccountIdentifier.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.AccountIdentifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.AccountIdentifier.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
};

/**
 * optional bytes address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.AccountIdentifier.prototype.getAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes address = 1;
 * This is a type-conversion wrapper around `getAddress()`
 * @return {string}
 */
TronWebProto.AccountIdentifier.prototype.getAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getAddress()));
};

/**
 * optional bytes address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.AccountIdentifier.prototype.getAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.AccountIdentifier} returns this
 */
TronWebProto.AccountIdentifier.prototype.setAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.AccountBalanceRequest.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.AccountBalanceRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.AccountBalanceRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.AccountBalanceRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                accountIdentifier:
                    (f = msg.getAccountIdentifier()) && TronWebProto.AccountIdentifier.toObject(includeInstance, f),
                blockIdentifier:
                    (f = msg.getBlockIdentifier()) && TronWebProto.BlockBalanceTrace.BlockIdentifier.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.AccountBalanceRequest}
 */
TronWebProto.AccountBalanceRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.AccountBalanceRequest();
    return TronWebProto.AccountBalanceRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.AccountBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.AccountBalanceRequest}
 */
TronWebProto.AccountBalanceRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new TronWebProto.AccountIdentifier();
                reader.readMessage(value, TronWebProto.AccountIdentifier.deserializeBinaryFromReader);
                msg.setAccountIdentifier(value);
                break;
            case 2:
                var value = new TronWebProto.BlockBalanceTrace.BlockIdentifier();
                reader.readMessage(value, TronWebProto.BlockBalanceTrace.BlockIdentifier.deserializeBinaryFromReader);
                msg.setBlockIdentifier(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.AccountBalanceRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.AccountBalanceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.AccountBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.AccountBalanceRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccountIdentifier();
    if (f != null) {
        writer.writeMessage(1, f, TronWebProto.AccountIdentifier.serializeBinaryToWriter);
    }
    f = message.getBlockIdentifier();
    if (f != null) {
        writer.writeMessage(2, f, TronWebProto.BlockBalanceTrace.BlockIdentifier.serializeBinaryToWriter);
    }
};

/**
 * optional AccountIdentifier account_identifier = 1;
 * @return {?TronWebProto.AccountIdentifier}
 */
TronWebProto.AccountBalanceRequest.prototype.getAccountIdentifier = function () {
    return /** @type{?TronWebProto.AccountIdentifier} */ (jspb.Message.getWrapperField(this, TronWebProto.AccountIdentifier, 1));
};

/**
 * @param {?TronWebProto.AccountIdentifier|undefined} value
 * @return {!TronWebProto.AccountBalanceRequest} returns this
 */
TronWebProto.AccountBalanceRequest.prototype.setAccountIdentifier = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.AccountBalanceRequest} returns this
 */
TronWebProto.AccountBalanceRequest.prototype.clearAccountIdentifier = function () {
    return this.setAccountIdentifier(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.AccountBalanceRequest.prototype.hasAccountIdentifier = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional BlockBalanceTrace.BlockIdentifier block_identifier = 2;
 * @return {?TronWebProto.BlockBalanceTrace.BlockIdentifier}
 */
TronWebProto.AccountBalanceRequest.prototype.getBlockIdentifier = function () {
    return /** @type{?TronWebProto.BlockBalanceTrace.BlockIdentifier} */ (
        jspb.Message.getWrapperField(this, TronWebProto.BlockBalanceTrace.BlockIdentifier, 2)
    );
};

/**
 * @param {?TronWebProto.BlockBalanceTrace.BlockIdentifier|undefined} value
 * @return {!TronWebProto.AccountBalanceRequest} returns this
 */
TronWebProto.AccountBalanceRequest.prototype.setBlockIdentifier = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.AccountBalanceRequest} returns this
 */
TronWebProto.AccountBalanceRequest.prototype.clearBlockIdentifier = function () {
    return this.setBlockIdentifier(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.AccountBalanceRequest.prototype.hasBlockIdentifier = function () {
    return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.AccountBalanceResponse.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.AccountBalanceResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.AccountBalanceResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.AccountBalanceResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                balance: jspb.Message.getFieldWithDefault(msg, 1, 0),
                blockIdentifier:
                    (f = msg.getBlockIdentifier()) && TronWebProto.BlockBalanceTrace.BlockIdentifier.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.AccountBalanceResponse}
 */
TronWebProto.AccountBalanceResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.AccountBalanceResponse();
    return TronWebProto.AccountBalanceResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.AccountBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.AccountBalanceResponse}
 */
TronWebProto.AccountBalanceResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBalance(value);
                break;
            case 2:
                var value = new TronWebProto.BlockBalanceTrace.BlockIdentifier();
                reader.readMessage(value, TronWebProto.BlockBalanceTrace.BlockIdentifier.deserializeBinaryFromReader);
                msg.setBlockIdentifier(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.AccountBalanceResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.AccountBalanceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.AccountBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.AccountBalanceResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBalance();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getBlockIdentifier();
    if (f != null) {
        writer.writeMessage(2, f, TronWebProto.BlockBalanceTrace.BlockIdentifier.serializeBinaryToWriter);
    }
};

/**
 * optional int64 balance = 1;
 * @return {number}
 */
TronWebProto.AccountBalanceResponse.prototype.getBalance = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.AccountBalanceResponse} returns this
 */
TronWebProto.AccountBalanceResponse.prototype.setBalance = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional BlockBalanceTrace.BlockIdentifier block_identifier = 2;
 * @return {?TronWebProto.BlockBalanceTrace.BlockIdentifier}
 */
TronWebProto.AccountBalanceResponse.prototype.getBlockIdentifier = function () {
    return /** @type{?TronWebProto.BlockBalanceTrace.BlockIdentifier} */ (
        jspb.Message.getWrapperField(this, TronWebProto.BlockBalanceTrace.BlockIdentifier, 2)
    );
};

/**
 * @param {?TronWebProto.BlockBalanceTrace.BlockIdentifier|undefined} value
 * @return {!TronWebProto.AccountBalanceResponse} returns this
 */
TronWebProto.AccountBalanceResponse.prototype.setBlockIdentifier = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!TronWebProto.AccountBalanceResponse} returns this
 */
TronWebProto.AccountBalanceResponse.prototype.clearBlockIdentifier = function () {
    return this.setBlockIdentifier(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
TronWebProto.AccountBalanceResponse.prototype.hasBlockIdentifier = function () {
    return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.FreezeBalanceV2Contract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.FreezeBalanceV2Contract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.FreezeBalanceV2Contract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.FreezeBalanceV2Contract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                frozenBalance: jspb.Message.getFieldWithDefault(msg, 2, 0),
                resource: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.FreezeBalanceV2Contract}
 */
TronWebProto.FreezeBalanceV2Contract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.FreezeBalanceV2Contract();
    return TronWebProto.FreezeBalanceV2Contract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.FreezeBalanceV2Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.FreezeBalanceV2Contract}
 */
TronWebProto.FreezeBalanceV2Contract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFrozenBalance(value);
                break;
            case 3:
                var value = /** @type {!TronWebProto.ResourceCode} */ (reader.readEnum());
                msg.setResource(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.FreezeBalanceV2Contract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.FreezeBalanceV2Contract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.FreezeBalanceV2Contract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.FreezeBalanceV2Contract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getFrozenBalance();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getResource();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.FreezeBalanceV2Contract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.FreezeBalanceV2Contract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.FreezeBalanceV2Contract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.FreezeBalanceV2Contract} returns this
 */
TronWebProto.FreezeBalanceV2Contract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional int64 frozen_balance = 2;
 * @return {number}
 */
TronWebProto.FreezeBalanceV2Contract.prototype.getFrozenBalance = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.FreezeBalanceV2Contract} returns this
 */
TronWebProto.FreezeBalanceV2Contract.prototype.setFrozenBalance = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional ResourceCode resource = 3;
 * @return {!TronWebProto.ResourceCode}
 */
TronWebProto.FreezeBalanceV2Contract.prototype.getResource = function () {
    return /** @type {!TronWebProto.ResourceCode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {!TronWebProto.ResourceCode} value
 * @return {!TronWebProto.FreezeBalanceV2Contract} returns this
 */
TronWebProto.FreezeBalanceV2Contract.prototype.setResource = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.UnfreezeBalanceV2Contract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.UnfreezeBalanceV2Contract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.UnfreezeBalanceV2Contract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.UnfreezeBalanceV2Contract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                unfreezeBalance: jspb.Message.getFieldWithDefault(msg, 2, 0),
                resource: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.UnfreezeBalanceV2Contract}
 */
TronWebProto.UnfreezeBalanceV2Contract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.UnfreezeBalanceV2Contract();
    return TronWebProto.UnfreezeBalanceV2Contract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.UnfreezeBalanceV2Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.UnfreezeBalanceV2Contract}
 */
TronWebProto.UnfreezeBalanceV2Contract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUnfreezeBalance(value);
                break;
            case 3:
                var value = /** @type {!TronWebProto.ResourceCode} */ (reader.readEnum());
                msg.setResource(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.UnfreezeBalanceV2Contract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.UnfreezeBalanceV2Contract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.UnfreezeBalanceV2Contract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.UnfreezeBalanceV2Contract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getUnfreezeBalance();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getResource();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UnfreezeBalanceV2Contract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.UnfreezeBalanceV2Contract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UnfreezeBalanceV2Contract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UnfreezeBalanceV2Contract} returns this
 */
TronWebProto.UnfreezeBalanceV2Contract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional int64 unfreeze_balance = 2;
 * @return {number}
 */
TronWebProto.UnfreezeBalanceV2Contract.prototype.getUnfreezeBalance = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.UnfreezeBalanceV2Contract} returns this
 */
TronWebProto.UnfreezeBalanceV2Contract.prototype.setUnfreezeBalance = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional ResourceCode resource = 3;
 * @return {!TronWebProto.ResourceCode}
 */
TronWebProto.UnfreezeBalanceV2Contract.prototype.getResource = function () {
    return /** @type {!TronWebProto.ResourceCode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {!TronWebProto.ResourceCode} value
 * @return {!TronWebProto.UnfreezeBalanceV2Contract} returns this
 */
TronWebProto.UnfreezeBalanceV2Contract.prototype.setResource = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.WithdrawExpireUnfreezeContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.WithdrawExpireUnfreezeContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.WithdrawExpireUnfreezeContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.WithdrawExpireUnfreezeContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.WithdrawExpireUnfreezeContract}
 */
TronWebProto.WithdrawExpireUnfreezeContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.WithdrawExpireUnfreezeContract();
    return TronWebProto.WithdrawExpireUnfreezeContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.WithdrawExpireUnfreezeContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.WithdrawExpireUnfreezeContract}
 */
TronWebProto.WithdrawExpireUnfreezeContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.WithdrawExpireUnfreezeContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.WithdrawExpireUnfreezeContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.WithdrawExpireUnfreezeContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.WithdrawExpireUnfreezeContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.WithdrawExpireUnfreezeContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.WithdrawExpireUnfreezeContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.WithdrawExpireUnfreezeContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.WithdrawExpireUnfreezeContract} returns this
 */
TronWebProto.WithdrawExpireUnfreezeContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.DelegateResourceContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.DelegateResourceContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.DelegateResourceContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.DelegateResourceContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                resource: jspb.Message.getFieldWithDefault(msg, 2, 0),
                balance: jspb.Message.getFieldWithDefault(msg, 3, 0),
                receiverAddress: msg.getReceiverAddress_asB64(),
                lock: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
                lockPeriod: jspb.Message.getFieldWithDefault(msg, 6, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.DelegateResourceContract}
 */
TronWebProto.DelegateResourceContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.DelegateResourceContract();
    return TronWebProto.DelegateResourceContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.DelegateResourceContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.DelegateResourceContract}
 */
TronWebProto.DelegateResourceContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = /** @type {!TronWebProto.ResourceCode} */ (reader.readEnum());
                msg.setResource(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBalance(value);
                break;
            case 4:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setReceiverAddress(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setLock(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLockPeriod(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.DelegateResourceContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.DelegateResourceContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.DelegateResourceContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.DelegateResourceContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getResource();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getBalance();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getReceiverAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(4, f);
    }
    f = message.getLock();
    if (f) {
        writer.writeBool(5, f);
    }
    f = message.getLockPeriod();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.DelegateResourceContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.DelegateResourceContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.DelegateResourceContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.DelegateResourceContract} returns this
 */
TronWebProto.DelegateResourceContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional ResourceCode resource = 2;
 * @return {!TronWebProto.ResourceCode}
 */
TronWebProto.DelegateResourceContract.prototype.getResource = function () {
    return /** @type {!TronWebProto.ResourceCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!TronWebProto.ResourceCode} value
 * @return {!TronWebProto.DelegateResourceContract} returns this
 */
TronWebProto.DelegateResourceContract.prototype.setResource = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional int64 balance = 3;
 * @return {number}
 */
TronWebProto.DelegateResourceContract.prototype.getBalance = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.DelegateResourceContract} returns this
 */
TronWebProto.DelegateResourceContract.prototype.setBalance = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional bytes receiver_address = 4;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.DelegateResourceContract.prototype.getReceiverAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * optional bytes receiver_address = 4;
 * This is a type-conversion wrapper around `getReceiverAddress()`
 * @return {string}
 */
TronWebProto.DelegateResourceContract.prototype.getReceiverAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getReceiverAddress()));
};

/**
 * optional bytes receiver_address = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReceiverAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.DelegateResourceContract.prototype.getReceiverAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getReceiverAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.DelegateResourceContract} returns this
 */
TronWebProto.DelegateResourceContract.prototype.setReceiverAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 4, value);
};

/**
 * optional bool lock = 5;
 * @return {boolean}
 */
TronWebProto.DelegateResourceContract.prototype.getLock = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};

/**
 * @param {boolean} value
 * @return {!TronWebProto.DelegateResourceContract} returns this
 */
TronWebProto.DelegateResourceContract.prototype.setLock = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional int64 lock_period = 6;
 * @return {number}
 */
TronWebProto.DelegateResourceContract.prototype.getLockPeriod = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.DelegateResourceContract} returns this
 */
TronWebProto.DelegateResourceContract.prototype.setLockPeriod = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.UnDelegateResourceContract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.UnDelegateResourceContract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.UnDelegateResourceContract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.UnDelegateResourceContract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
                resource: jspb.Message.getFieldWithDefault(msg, 2, 0),
                balance: jspb.Message.getFieldWithDefault(msg, 3, 0),
                receiverAddress: msg.getReceiverAddress_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.UnDelegateResourceContract}
 */
TronWebProto.UnDelegateResourceContract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.UnDelegateResourceContract();
    return TronWebProto.UnDelegateResourceContract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.UnDelegateResourceContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.UnDelegateResourceContract}
 */
TronWebProto.UnDelegateResourceContract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            case 2:
                var value = /** @type {!TronWebProto.ResourceCode} */ (reader.readEnum());
                msg.setResource(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBalance(value);
                break;
            case 4:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setReceiverAddress(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.UnDelegateResourceContract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.UnDelegateResourceContract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.UnDelegateResourceContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.UnDelegateResourceContract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
    f = message.getResource();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getBalance();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getReceiverAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(4, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UnDelegateResourceContract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.UnDelegateResourceContract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UnDelegateResourceContract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UnDelegateResourceContract} returns this
 */
TronWebProto.UnDelegateResourceContract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional ResourceCode resource = 2;
 * @return {!TronWebProto.ResourceCode}
 */
TronWebProto.UnDelegateResourceContract.prototype.getResource = function () {
    return /** @type {!TronWebProto.ResourceCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!TronWebProto.ResourceCode} value
 * @return {!TronWebProto.UnDelegateResourceContract} returns this
 */
TronWebProto.UnDelegateResourceContract.prototype.setResource = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};

/**
 * optional int64 balance = 3;
 * @return {number}
 */
TronWebProto.UnDelegateResourceContract.prototype.getBalance = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!TronWebProto.UnDelegateResourceContract} returns this
 */
TronWebProto.UnDelegateResourceContract.prototype.setBalance = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional bytes receiver_address = 4;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.UnDelegateResourceContract.prototype.getReceiverAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * optional bytes receiver_address = 4;
 * This is a type-conversion wrapper around `getReceiverAddress()`
 * @return {string}
 */
TronWebProto.UnDelegateResourceContract.prototype.getReceiverAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getReceiverAddress()));
};

/**
 * optional bytes receiver_address = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReceiverAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.UnDelegateResourceContract.prototype.getReceiverAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getReceiverAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.UnDelegateResourceContract} returns this
 */
TronWebProto.UnDelegateResourceContract.prototype.setReceiverAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    TronWebProto.CancelAllUnfreezeV2Contract.prototype.toObject = function (opt_includeInstance) {
        return TronWebProto.CancelAllUnfreezeV2Contract.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!TronWebProto.CancelAllUnfreezeV2Contract} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    TronWebProto.CancelAllUnfreezeV2Contract.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ownerAddress: msg.getOwnerAddress_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!TronWebProto.CancelAllUnfreezeV2Contract}
 */
TronWebProto.CancelAllUnfreezeV2Contract.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TronWebProto.CancelAllUnfreezeV2Contract();
    return TronWebProto.CancelAllUnfreezeV2Contract.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!TronWebProto.CancelAllUnfreezeV2Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!TronWebProto.CancelAllUnfreezeV2Contract}
 */
TronWebProto.CancelAllUnfreezeV2Contract.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setOwnerAddress(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
TronWebProto.CancelAllUnfreezeV2Contract.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    TronWebProto.CancelAllUnfreezeV2Contract.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!TronWebProto.CancelAllUnfreezeV2Contract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
TronWebProto.CancelAllUnfreezeV2Contract.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerAddress_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
};

/**
 * optional bytes owner_address = 1;
 * @return {!(string|Uint8Array)}
 */
TronWebProto.CancelAllUnfreezeV2Contract.prototype.getOwnerAddress = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * optional bytes owner_address = 1;
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {string}
 */
TronWebProto.CancelAllUnfreezeV2Contract.prototype.getOwnerAddress_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getOwnerAddress()));
};

/**
 * optional bytes owner_address = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOwnerAddress()`
 * @return {!Uint8Array}
 */
TronWebProto.CancelAllUnfreezeV2Contract.prototype.getOwnerAddress_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getOwnerAddress()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!TronWebProto.CancelAllUnfreezeV2Contract} returns this
 */
TronWebProto.CancelAllUnfreezeV2Contract.prototype.setOwnerAddress = function (value) {
    return jspb.Message.setProto3BytesField(this, 1, value);
};

goog.object.extend(exports, TronWebProto);
